<template>
  <div>
    <div class="operationControl flexdc" style="align-items: flex-start;">
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem">
          <span class="itemLabel">课程名称:</span>
          <el-input v-model="courseName" type="text" size="small" placeholder="请输入课程名称" clearable />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            modal
            typeStu
          />
        </span>
        <div title="归属企业" class="searchboxItem ci-full">
          <span class="itemLabel">归属企业:</span>
          <el-select
            size="small"
            v-model="compSourceId"
            remote
            :remote-method="getCompSourceList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in compSourceList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div title="提交企业" class="searchboxItem ci-full">
          <span class="itemLabel" style="width:80px">提交企业:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin-top:15px">
        <div title="审核时间:" class="searchboxItem ci-full">
          <span class="itemLabel" style="width:80px">审核时间:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="ArraignmentTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          ></el-date-picker>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :stripe="true"
          :header-cell-style="tableHeader"
        >
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px"/>
          <el-table-column
            label="课程名称"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            fixed
            width="250"
          />
          <el-table-column
            label="培训类型"
            align="left"
            :show-overflow-tooltip="true"
            prop="trainTypeNamePath"
            width="140"
          />
          <el-table-column label="行业类型" align="left" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ scope.row.industryNamePath || "--" }}</template>
          </el-table-column>
          <el-table-column label="岗位类型" align="left" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ scope.row.postName || "--" }}</template>
          </el-table-column>
          <el-table-column label="职业/工种" align="left" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ scope.row.occupationNamePath || "--" }}</template>
          </el-table-column>
          <el-table-column label="培训等级" align="left" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ scope.row.trainLevelName || "--" }}</template>
          </el-table-column>
          <el-table-column label="课件数量" align="right" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ scope.row.kpointTotalNum || "--" }}</template>
          </el-table-column>
          <el-table-column label="视频总时长" align="right" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">{{ getTime(scope.row.kpointTotalDuration) || "--" }}</template>
          </el-table-column>
          <el-table-column label="课程归属企业" align="left" :show-overflow-tooltip="true" width="250">
            <template slot-scope="scope">{{ scope.row.compSourceName || "--" }}</template>
          </el-table-column>
          <el-table-column
            label="提交企业"
            align="left"
            show-overflow-tooltip
            prop="compName"
            width="250"
          />
          <el-table-column
            label="原课程"
            align="left"
            show-overflow-tooltip
            prop="courseSourceName"
            width="250"
          >
          <template slot-scope="scope">
            {{scope.row.courseSourceName || '--'}}
          </template>
          </el-table-column>
          <el-table-column
            label="上传时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
            width="140"
          >
            <template slot-scope="scope">{{ scope.row.createTime | moment }}</template>
          </el-table-column>
          <el-table-column
            label="审核人"
            align="left"
            show-overflow-tooltip
            prop="auditor"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.auditor || "无" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="审核时间"
            align="left"
            show-overflow-tooltip
            prop="auditTime"
            width="140"
          >
            <template slot-scope="scope">{{ scope.row.auditTime | moment }}</template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="left"
            show-overflow-tooltip
            minWidth="150"
            prop="remark"
          >
            <template slot-scope="scope">{{
              scope.row.remark || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            :show-overflow-tooltip="true"
            fixed="right"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                style="padding:0 5px"
                type="text"
                size="mini"
                @click="doReview(scope.row.courseId)"
              >详情</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "Coursepassed",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      compSourceId: "",
      compId: "",
      ArraignmentTime: [],
      compSourceList: [],
      CompanyList: [],
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  computed: {},
  methods: {
    init() {
      this.getTableHeight();
      this.getData(-1);
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.compSourceId) {
        params.compSourceId = this.compSourceId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.ArraignmentTime) {
        params.startAuditTime = this.ArraignmentTime[0];
        params.endAuditTime = this.ArraignmentTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/course/auditPassListPage",
        params,
        pageNum
      });
    },
    //详情
    doReview(courseId) {
      this.$router.push({
        path: "/web/CourseReviewDetails",
        query: {
          courseId,
          active: "second",
          stu: "look"
        }
      });
    },
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 资源提供者2
    getCompSourceList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.compSourceList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.compSourceList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    }
  }
  //  watch: {
  //   $route: {
  //     handler: function(val, oldVal) {
  //       if (oldVal.meta.level == 2) {
  //         this.getData(-1);
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true
  //   }
  // }
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
